module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-MYJLD6CVSN","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@bitpas/gatsby-plugin-seo/gatsby-browser.js'),
      options: {"plugins":[],"helmet":{"link":[{"rel":"icon","href":"/favicon.svg","media":"(prefers-color-scheme: no-preference)"},{"rel":"icon","href":"/favicon.svg","media":"(prefers-color-scheme: light)"},{"rel":"icon","href":"/favicon-inverted.svg","media":"(prefers-color-scheme: dark)"}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*icon*"]}},
    }]
